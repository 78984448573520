import React, { useState, useEffect , useCallback  } from 'react';
import { Line } from 'react-chartjs-2';
import { Chart as ChartJS, LineElement, CategoryScale, LinearScale, PointElement, Legend, Tooltip } from 'chart.js';
import 'chart.js/auto';
import Dropdown from 'react-bootstrap/Dropdown';
import './Dashboard.css';

import { api } from "../../utility/api";

const Dashboard = () => {

  const [percentageChange, setPercentageChange] = useState(0);
  const [selectedFilterType, setSelectedFilterType] = useState('Subscriptions');

  const [selectedTimeRangeAllStats, setSelectedTimeRangeAllStats] = useState('1 Month');
  const [selectedTimeRangeEarningsData, setSelectedTimeRangeEarningsData] = useState('1 Month');
  const [selectedTimeRangeEarningsChart, setSelectedTimeRangeEarningsChart] = useState('1 Month');
  const [selectedTimeRangeFollowers, setSelectedTimeRangeFollowers] = useState('1 Month');





  const [fromDate, setFromDate] = useState(null);
  const [toDate, setToDate] = useState(null);


  const [stats, setStats] = useState({
    profileVisits: 0,
    newFollowers: 0,
    newSubscribers: 0,
    totalPosts: 0,
    totalGigs: 0,
  });

  const [earnings, setEarnings] = useState({
    subscriptions: 0,
    tips: 0,
    gigUnlocks: 0,
    renewals: 0,
    gigJobs: 0,
    overall: 0,
    spCommission: 0,
    totalEarnings: 0
  });

  const [topCreators, setTopCreators] = useState({
    topSubscribed: [],
    topEarning: []
  });


  const [earningsChartData, setEarningsChartData] = useState(null);
  const [followersSubscribersChartData, setFollowersSubscribersChartData] = useState(null);

  const handleFilterTypeClick = (type) => {
    setSelectedFilterType(type);
  };


  const handleDropdownClickAllStats = (range) => {
    setSelectedTimeRangeAllStats(range);

    let filter;
    switch (range) {
      case 'Week':
        filter = 'week';
        break;
      case '1 Month':
        filter = 'month';
        break;
      case 'Last 3 Months':
        filter = '3_months';
        break;
      case 'Last 6 Months':
        filter = '6_months';
        break;
      case 'Last 1 Year':
        filter = 'year';
        break;
      default:
        filter = 'month';
    }

    fetchStats(filter);
  };

  // Handler for Earnings Data dropdown
  const handleDropdownClickEarningsData = (range) => {
    setSelectedTimeRangeEarningsData(range);

    let filter;
    switch (range) {
      case 'Week':
        filter = 'week';
        break;
      case '1 Month':
        filter = 'month';
        break;
      case 'Last 3 Months':
        filter = '3_months';
        break;
      case 'Last 6 Months':
        filter = '6_months';
        break;
      case 'Last 1 Year':
        filter = 'year';
        break;
      default:
        filter = 'month';
    }

    fetchEarningsData(filter);
  };

  // Handler for Earnings Chart dropdown
  const handleDropdownClickEarningsChart = (range) => {
    setSelectedTimeRangeEarningsChart(range);

    let filter;
    switch (range) {
      case 'Week':
        filter = 'week';
        break;
      case '1 Month':
        filter = 'month';
        break;
      case 'Last 3 Months':
        filter = '3_months';
        break;
      case 'Last 6 Months':
        filter = '6_months';
        break;
      case 'Last 1 Year':
        filter = 'year';
        break;
      default:
        filter = 'month';
    }

    fetchEarningsChartData(filter);
  };

  // Handler for Followers dropdown
  const handleDropdownClickFollowers = (range) => {
    setSelectedTimeRangeFollowers(range);

    let filter;
    switch (range) {
      case 'Week':
        filter = 'week';
        break;
      case '1 Month':
        filter = 'month';
        break;
      case 'Last 3 Months':
        filter = '3_months';
        break;
      case 'Last 6 Months':
        filter = '6_months';
        break;
      case 'Last 1 Year':
        filter = 'year';
        break;
      default:
        filter = 'month';
    }

    fetchFollowersSubscribersChartData(filter);
  };


  const fetchStats = async (filter) => {
    try {
      const response = await api.get(`dashboard-all-stats?filter=${filter}`);
      if (response.data.success) {
        setStats({
          profileVisits: response.data.profile_visits,
          newFollowers: response.data.new_followers,
          newSubscribers: response.data.new_subscribers,
          totalPosts: response.data.total_posts,
          totalGigs: response.data.total_gigs,
        });
      }
    } catch (error) {
      console.error('Error fetching stats:', error);
    }
  };

  const fetchEarningsData = useCallback(async (filter) => {
    try {
      const response = await api.get(`dashboard-earning-data?filter=${filter}&filter_type=${selectedFilterType}`);
      if (response.data.success) {
        setEarnings({
          subscriptions: response.data.earnings_breakdown.subscriptions,
          tips: response.data.earnings_breakdown.tips,
          gigUnlocks: response.data.earnings_breakdown.gig_unlocks,
          renewals: response.data.earnings_breakdown.renewals,
          gigJobs: response.data.earnings_breakdown.gig_jobs,
          overall: response.data.overall,
          spCommission: response.data.sp_commission,
          totalEarnings: response.data.total_earnings,
        });
      }
    } catch (error) {
      console.error('Error fetching earnings data:', error);
    }
  }, [selectedFilterType]); 
  

  const fetchEarningsChartData = useCallback(async (filter) => {
    try {
      const response = await api.get(`dashboard-earning-chart?filter=${filter}`);
      if (response.data.success) {
        setPercentageChange(response.data.percentage_change);

        const chartData = {
          labels: response.data.labels,
          datasets: [
            {
              label: 'Earnings',
              data: response.data.earnings,
              fill: true,
              backgroundColor: 'rgba(255, 159, 64, 0.5)',
              borderColor: 'rgba(255, 159, 64, 1)',
              borderWidth: 2,
              tension: 0.4,
            },
          ],
        };
        setEarningsChartData(chartData);
      }
    } catch (error) {
      console.error('Error fetching earnings chart data:', error);
    }
  }, []); 

  const fetchFollowersSubscribersChartData = async (filter) => {
    try {
      const response = await api.get(`dashboard-line-chart?filter=${filter}`);
      if (response.data.success) {
        setFromDate(response.data.from_date);
        setToDate(response.data.to_date);

        const chartData = {
          labels: response.data.labels,
          datasets: [
            {
              label: 'Followers',
              data: response.data.followers,
              fill: false,
              backgroundColor: '#FFCC00',
              borderColor: '#F5A84A',
              borderWidth: 2,
            },
            {
              label: 'Subscribers',
              data: response.data.subscribers,
              fill: false,
              backgroundColor: '#404040',
              borderColor: '#404040',
              borderWidth: 2,
            },
          ],
        };
        setFollowersSubscribersChartData(chartData);
      }
    } catch (error) {
      console.error('Error fetching followers and subscribers chart data:', error);
    }
  };


  useEffect(() => {
    fetchStats('month');
    fetchEarningsData('month');
    fetchEarningsChartData('month');
    fetchFollowersSubscribersChartData('month');
  }, [fetchEarningsChartData, fetchEarningsData]);
  


  ChartJS.register(LineElement, CategoryScale, LinearScale, PointElement, Legend, Tooltip);

  // Chart options
  const chartOptions = {
    responsive: true,
    maintainAspectRatio: false,
    plugins: {
      legend: {
        display: false,
      },
    },
    scales: {
      x: {
        title: {
          display: true,
          text: 'Date',
        },
        grid: {
          display: false,
        },
      },
      y: {
        title: {
          display: true,
          text: 'Count',
        },
        min: 0,
        beginAtZero: true,
        grid: {
          display: false,
        },
      },
    },
  };

  const chartOptions_earning = {
    responsive: true,
    maintainAspectRatio: false,
    plugins: {
      legend: {
        display: false,
      },
    },
    scales: {
      x: {
        title: {
          display: true,
          text: 'Date',
        },
        grid: {
          display: false,
        },
      },
      y: {
        title: {
          display: true,
          text: 'Earnings (€)',
        },
        min: 0,
        beginAtZero: true,
        grid: {
          display: false,
        },
        ticks: {
          callback: function (value) {
            return `€${value}`;
          },
        },
      },
    },
  };


  const fetchStats_certores = async () => {
    try {
      const response = await api.get('dashboard-all-stats');
      if (response.data.success) {
        setStats({
          profileVisits: response.data.profile_visits,
          newFollowers: response.data.new_followers,
          newSubscribers: response.data.new_subscribers,
          totalPosts: response.data.total_posts,
          totalGigs: response.data.total_gigs
        });
      }
    } catch (error) {
      console.error('Error fetching stats:', error);
    }
  };


  const fetchEarnings = async () => {
    try {
      const response = await api.get('dashboard-earning-data');
      if (response.data.success) {
        setEarnings({
          subscriptions: response.data.earnings_breakdown.subscriptions,
          tips: response.data.earnings_breakdown.tips,
          gigUnlocks: response.data.earnings_breakdown.gig_unlocks,
          renewals: response.data.earnings_breakdown.renewals,
          gigJobs: response.data.earnings_breakdown.gig_jobs,
          overall: response.data.overall,
          spCommission: response.data.sp_commission,
          totalEarnings: response.data.total_earnings
        });
      }
    } catch (error) {
      console.error('Error fetching earnings:', error);
    }
  };


  const fetchTopCreators = async () => {
    try {
      const response = await api.get('dashboard-top-creator');
      if (response.data.success) {
        setTopCreators({
          topSubscribed: response.data.top_subscriber_creators,
          topEarning: response.data.top_earning_creators
        });
      }
    } catch (error) {
      console.error('Error fetching top creators:', error);
    }
  };


  const fetchEarningsChartData_certores = async () => {
    try {
      const response = await api.get('dashboard-earning-chart');
      if (response.data.success) {
        const chartData = {
          labels: response.data.labels,
          datasets: [
            {
              label: 'Earnings',
              data: response.data.earnings,
              fill: false,
              backgroundColor: 'rgba(75, 192, 192, 0.2)',
              borderColor: 'rgba(75, 192, 192, 1)',
              borderWidth: 2,
            },
          ],
        };
        setEarningsChartData(chartData);
      }
    } catch (error) {
      console.error('Error fetching earnings chart data:', error);
    }
  };


  const fetchFollowersSubscribersChartData_certores = async () => {
    try {
      const response = await api.get('dashboard-line-chart');
      if (response.data.success) {
        const chartData = {
          labels: response.data.labels,
          datasets: [
            {
              label: 'Followers',
              data: response.data.followers,
              fill: false,
              backgroundColor: 'rgba(255, 99, 132, 0.2)',
              borderColor: 'rgba(255, 99, 132, 1)',
              borderWidth: 2,
            },
            {
              label: 'Subscribers',
              data: response.data.subscribers,
              fill: false,
              backgroundColor: 'rgba(54, 162, 235, 0.2)',
              borderColor: 'rgba(54, 162, 235, 1)',
              borderWidth: 2,
            },
          ],
        };
        setFollowersSubscribersChartData(chartData);
      }
    } catch (error) {
      console.error('Error fetching followers and subscribers chart data:', error);
    }
  };


  // const query = new URLSearchParams(useLocation().search);
  // const dispatch = useDispatch();
  // const userId = query.get('user_id');

  useEffect(() => {
    //console.log('User ID from URL:', userId);
    // if (!isAuthenticated) {
    //   dispatch(login(userId));
    // }


    
    
      fetchStats_certores();
    fetchEarnings();
    fetchTopCreators();
    fetchEarningsChartData_certores();
    fetchFollowersSubscribersChartData_certores();
    
    
  }, []);




  // Call the function to test it
  //fetchData();

  return (
    <div className='main-dahboard'>
      {/* Dashboard Start */}
      <div className="dashboard-container">
        {/* Drop Down Menu */}
        <div className="dashboard-header d-flex justify-content-between align-items-center">
          <h2 className="all-stats-heading">All Stats</h2>
          <Dropdown>
            <Dropdown.Toggle variant="light" id="custom-dropdown-basic" className="custom-toggle-button">
              {selectedTimeRangeAllStats}
            </Dropdown.Toggle>
            <Dropdown.Menu className="custom-dropdown-menu">
              {['Week', '1 Month', 'Last 3 Months', 'Last 6 Months', 'Last 1 Year'].map((range, index) => (
                <Dropdown.Item
                  key={index}
                  onClick={() => handleDropdownClickAllStats(range)} 
                  className="custom-menu-item"
                >
                  {range}
                </Dropdown.Item>
              ))}
            </Dropdown.Menu>
          </Dropdown>

        </div>

        {/* Stats Grid */}
        <div className="stats-grid">
          <div className="stat-card">
            <img src={`${process.env.PUBLIC_URL}/icons/Dashboardvisit.svg`} alt="Profile Visits" className="stat-icon" />
            <p>Profile Visits</p>
            <h3>{stats.profileVisits}</h3>
          </div>
          <div className="stat-card">
            <img src={`${process.env.PUBLIC_URL}/icons/Followers.svg`} alt="New Followers" className="stat-icon" />
            <p>New Followers</p>
            <h3>{stats.newFollowers}</h3>
          </div>
          <div className="stat-card">
            <img src={`${process.env.PUBLIC_URL}/icons/Subcribers.svg`} alt="New Subscribers" className="stat-icon" />
            <p>New Subscribers</p>
            <h3>{stats.newSubscribers}</h3>
          </div>
          <div className="stat-card">
            <img src={`${process.env.PUBLIC_URL}/icons/Posts.svg`} alt="Posts" className="stat-icon" />
            <p>Posts</p>
            <h3>{stats.totalPosts}</h3>
          </div>
          <div className="stat-card">
            <img src={`${process.env.PUBLIC_URL}/icons/LiveGigs.svg`} alt="Gigs" className="stat-icon" />
            <p>Gigs</p>
            <h3>{stats.totalGigs}</h3>
          </div>
        </div>
      </div>
      {/* Dashboard End */}

      {/* Earnings Start */}
      <div className="dashboard-earnings-container">
        <div className="row">
          {/* Earnings Left Column */}
          <div className="col-lg-4 col-md-12 earnings-container col-gap w-25">
            <h3>Earnings</h3>
            <Dropdown>
              <Dropdown.Toggle variant="light" id="custom-dropdown-basic" className="custom-toggle-button">
                {selectedTimeRangeEarningsData}
              </Dropdown.Toggle>
              <Dropdown.Menu className="custom-dropdown-menu">
                {['Week', '1 Month', 'Last 3 Months', 'Last 6 Months', 'Last 1 Year'].map((range, index) => (
                  <Dropdown.Item
                    key={index}
                    onClick={() => handleDropdownClickEarningsData(range)} 
                    className="custom-menu-item"
                  >
                    {range}
                  </Dropdown.Item>
                ))}
              </Dropdown.Menu>
            </Dropdown>


            <ul className="earnings-list">
              <li><span>Subscriptions</span><span>€{earnings.subscriptions}</span></li>
              <li><span>Tips</span><span>€{earnings.tips}</span></li>
              <li><span>Gig Unlocks</span><span>€{earnings.gigUnlocks}</span></li>
              <li><span>Renewals</span><span>€{earnings.renewals}</span></li>
              <li><span>Gig Jobs</span><span>€{earnings.gigJobs}</span></li>
              <li className="total"><span>Overall</span><span>€{earnings.overall}</span></li>
              <li><span>SP Commission (15%)</span><span>€{earnings.spCommission}</span></li>
              <li className="total"><span>Total Earnings</span><span className="total-amount">€{earnings.totalEarnings}</span></li>
            </ul>
          </div>

          {/* Earnings Chart Right Column */}
          <div className="col-lg-8 col-md-12 earnings-container">
            <div className="d-flex justify-content-between align-items-center mb-2 earnings_chart">
              <h4>Earnings Stats</h4>

              {/* Percentage Change */}
              <div className="percentage-change-container">
                <span style={{ backgroundColor: percentageChange > 0 ? 'green' : 'red', color: "white", padding: "12px", borderRadius: "8px" }}>
                  {percentageChange > 0 ? '↑' : '↓'} {Math.abs(percentageChange)}%
                </span>
              </div>

              <Dropdown>
                <Dropdown.Toggle variant="light" id="custom-dropdown-basic" className="custom-toggle-button">
                  {selectedFilterType}
                </Dropdown.Toggle>
                <Dropdown.Menu>
                  {['Subscriptions', 'Tips', 'Gig', 'Private Gig', 'Unlock', 'Renewals'].map((type, index) => (
                    <Dropdown.Item
                      key={index}
                      onClick={() => handleFilterTypeClick(type)}
                    >
                      {type}
                    </Dropdown.Item>
                  ))}
                </Dropdown.Menu>
              </Dropdown>

              {/* Time Range Dropdown */}
              <Dropdown>
                <Dropdown.Toggle variant="light" id="custom-dropdown-basic" className="custom-toggle-button">
                  {selectedTimeRangeEarningsChart}
                </Dropdown.Toggle>
                <Dropdown.Menu>
                  {['Week', '1 Month', 'Last 3 Months', 'Last 6 Months', 'Last 1 Year'].map((range, index) => (
                    <Dropdown.Item
                      key={index}
                      onClick={() => handleDropdownClickEarningsChart(range)} 
                    >
                      {range}
                    </Dropdown.Item>
                  ))}
                </Dropdown.Menu>
              </Dropdown>

            </div>
            <div style={{ width: '100%', height: '500px' }}>
              {earningsChartData ? (
                <Line data={earningsChartData} options={chartOptions_earning} />
              ) : (
                <p>Loading earnings chart...</p>
              )}
            </div>
          </div>
        </div>
      </div>
      {/* Earnings End */}

      {/* Followers & Subscribers Chart Start */}
      <div className='dashboard-container_followers'>
        <div className="d-flex justify-content-between align-items-center mb-2">
          <h4>Followers & Subscribers Stats</h4>

          <div className="custom-legend_dashboard">
            <span style={{ color: "#FF8E0E" }}><i className="blue-line_dashboard"></i> Followers</span>
            <span style={{ color: "#404040" }}><i className="orange-line_dashboard"></i> Subscribers</span>
          </div>
          <Dropdown>
            <Dropdown.Toggle variant="light" id="custom-dropdown-basic" className="custom-toggle-button">
              {selectedTimeRangeFollowers}
            </Dropdown.Toggle>
            <Dropdown.Menu>
              {['Week', '1 Month', 'Last 3 Months', 'Last 6 Months', 'Last 1 Year'].map((range, index) => (
                <Dropdown.Item
                  key={index}
                  onClick={() => handleDropdownClickFollowers(range)} 
                >
                  {range}
                </Dropdown.Item>
              ))}
            </Dropdown.Menu>
          </Dropdown>

        </div>
        {fromDate && toDate && <p>({fromDate} - {toDate})</p>}
        {/* Custom Legend */}

        {/* Chart */}
        <div style={{ width: '100%', height: '400px' }}>
          {followersSubscribersChartData ? (
            <Line data={followersSubscribersChartData} options={chartOptions} />
          ) : (
            <p>Loading followers and subscribers chart...</p>
          )}
        </div>

      </div>
      {/* Followers & Subscribers Chart End */}

      {/* Top Subscribed Creators And Top Earning Creators Start */}
      <div className="creator-container">
        <div className="container-fluid">
          <div className="row">
            {/* Top Subscribed Creators */}
            <div className="col-lg-6 col-md-12 mb-4">
              <div className="topcreator-card">
                <div className="topcreator-card-header bg-dark text-white">
                  Top Subscribed Creators
                </div>
                <div className="topcreator-card-body">
                  {topCreators.topSubscribed.map((creator) => (
                    <div className="topcreator-item d-flex align-items-center mb-3" key={creator.id}>
                      <img src={creator.avatar} alt="Profile" className="topcreator-img" />
                      <div className="ml-3">
                        <p className="mb-0">{creator.name}</p>
                        <span>@{creator.username}</span>
                      </div>
                      <div className="ml-auto">{creator.subscriber_count} Subscribers</div>
                    </div>
                  ))}
                </div>
              </div>
            </div>

            {/* Top Earning Creators */}
            <div className="col-lg-6 col-md-12 mb-4 mb-custom">
              <div className="topcreator-card">
                <div className="topcreator-card-header bg-dark text-white">
                  Top Earning Creators
                </div>
                <div className="topcreator-card-body">
                  {topCreators.topEarning.map((creator) => (
                    <div className="topcreator-item d-flex align-items-center mb-3" key={creator.id}>
                      <img src={creator.avatar} alt="Profile" className="topcreator-img" />
                      <div className="ml-3">
                        <p className="mb-0">{creator.name}</p>
                        <span>@{creator.username}</span>
                      </div>
                      <div className="ml-auto">€{creator.total_earning}</div>
                    </div>
                  ))}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* Top Subscribed Creators And Top Earning Creators End */}
    </div>
  );
};

export default Dashboard;
