import React, { useState, useEffect } from "react";
import { Container, Row, Col, Form, Button } from "react-bootstrap";
import { api } from "../../utility/api";
import "./Profile.css";

const Profile = () => {
  const [isEditMode, setIsEditMode] = useState(false); 
  const [profileData, setProfileData] = useState({
    name: "",
    username: "",
    first_name: "",
    last_name: "",
    email: "",
    phone: "",
    business_type: "",
    registration_vat_number: "",
    billing_address: "",
  });
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  const fetchProfileData = async () => {
    try {
      const response = await api.get("agency-profile-data");
      const data = response.data.data || {};
      setProfileData({
        name: data.name || "",
        username: data.username || "",
        first_name: data.first_name || "",
        last_name: data.last_name || "",
        email: data.email || "",
        phone: data.phone || "",
        business_type: data.business_type || "",
        registration_vat_number: data.registration_vat_number || "",
        billing_address: data.billing_address || "",
      });
      setLoading(false);
    } catch (error) {
      setError("Failed to fetch profile data");
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchProfileData();
  }, []);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setProfileData({
      ...profileData,
      [name]: value,
    });
  };

  const handleUpdateDetails = async () => {
    console.log("clckc the update");
    try {
      await api.put("agency-profile", profileData);
      alert("Profile updated successfully");
      setIsEditMode(false);  
    } catch (error) {
      console.error("Failed to update profile:", error);
      alert("Failed to update profile");
    }
  };

  

  



  if (loading) return <div>Loading...</div>;
  if (error) return <div>{error}</div>;
  return (
    <Container className="agency-details-container">
      {/* Agency Header */}
      <Row className="align-items-center justify-content-between agency-header">
        <Col xs={8} sm={8} md={6} lg={6}>
          <h5 className="text-white">Agency Details</h5>
        </Col>
        <Col xs={4} sm={4} md={6} lg={6} className="d-flex justify-content-end">
          <img
            src={`${process.env.PUBLIC_URL}/icons/Edit.svg`}
            alt="Edit"
            style={{ width: "32px", height: "32px", cursor: "pointer" }}
            onClick={() => setIsEditMode(!isEditMode)} // Toggle edit mode
          />
        </Col>
      </Row>

      {/* Agency Form */}
      <Form className="agency-form">
        <Row>
          <Col xs={12} md={6}>
            <Form.Group controlId="formAgencyName" className="mb-4">
              <Form.Label>Agency Name</Form.Label>
              <Form.Control
                type="text"
                name="name"
                value={profileData.name || ""}
                onChange={handleInputChange}
                readOnly={!isEditMode}
              />
            </Form.Group>
          </Col>
          <Col xs={12} md={6}>
            <Form.Group controlId="formUsername" className="mb-4">
              <Form.Label>Username</Form.Label>
              <Form.Control
                type="text"
                name="username"
                value={profileData.username || ""}
                onChange={handleInputChange}
                readOnly={!isEditMode}
              />
            </Form.Group>
          </Col>
        </Row>

        <Row>
          <Col xs={12} md={6}>
            <Form.Group controlId="formFirstName" className="mb-4">
              <Form.Label>First Name</Form.Label>
              <Form.Control
                type="text"
                name="first_name"
                value={profileData.first_name || ""}
                onChange={handleInputChange}
                readOnly={!isEditMode}
              />
            </Form.Group>
          </Col>
          <Col xs={12} md={6}>
            <Form.Group controlId="formLastName" className="mb-4">
              <Form.Label>Last Name</Form.Label>
              <Form.Control
                type="text"
                name="last_name"
                value={profileData.last_name || ""}
                onChange={handleInputChange}
                readOnly={!isEditMode}
              />
            </Form.Group>
          </Col>
        </Row>

        <Row>
          <Col xs={12} md={6}>
            <Form.Group controlId="formEmail" className="mb-4">
              <Form.Label>Email-id</Form.Label>
              <Form.Control
                type="email"
                name="email"
                value={profileData.email || ""}
                onChange={handleInputChange}
                readOnly={!isEditMode}
              />
            </Form.Group>
          </Col>
          <Col xs={12} md={6}>
            <Form.Group controlId="formPhone" className="mb-4">
              <Form.Label>Phone No</Form.Label>
              <Form.Control
                type="text"
                name="phone"
                value={profileData.phone || ""}
                onChange={handleInputChange}
                readOnly={!isEditMode}
              />
            </Form.Group>
          </Col>
        </Row>

        <Row>
          <Col xs={12} md={6}>
            <Form.Group controlId="formBusinessType" className="mb-4">
              <Form.Label>Business Type</Form.Label>
              <Form.Control
                type="text"
                name="business_type"
                value={profileData.business_type || ""}
                onChange={handleInputChange}
                readOnly={!isEditMode}
              />
            </Form.Group>
          </Col>
          <Col xs={12} md={6}>
            <Form.Group controlId="formVATNumber" className="mb-4">
              <Form.Label>Registration/VAT Number</Form.Label>
              <Form.Control
                type="text"
                name="registration_vat_number"
                value={profileData.registration_vat_number || ""}
                onChange={handleInputChange}
                readOnly={!isEditMode}
              />
            </Form.Group>
          </Col>
        </Row>

        <Row>
          <Col xs={12}>
            <Form.Group controlId="formAddress" className="mb-4">
              <Form.Label>Address</Form.Label>
              <Form.Control
                as="textarea"
                rows={3}
                name="billing_address"
                value={profileData.billing_address || ""}
                onChange={handleInputChange}
                readOnly={!isEditMode}
              />
            </Form.Group>
          </Col>
        </Row>

        {isEditMode && (
          <div className="text-center">
            <Button className="update-button" onClick={handleUpdateDetails}>
              Update Details
            </Button>
          </div>
        )}
      </Form>
    </Container>
  );
};

export default Profile;
