import React, { useState, useEffect } from "react";
import { Modal, Button, Form } from "react-bootstrap";
import "react-datepicker/dist/react-datepicker.css";
import "bootstrap/dist/css/bootstrap.min.css";
import "../Members/Members.css";
import { api } from "../../utility/api";

const Members = () => {

    const [errors, setErrors] = useState({});
    const [otpError, setOtpError] = useState("");
    const [searchQuery, setSearchQuery] = useState("");

    const [openItemIndex, setOpenItemIndex] = useState(null);
    const [roles, setRoles] = useState([]);
    const [members, setMembers] = useState([]);
    const [selectedMember, setSelectedMember] = useState(null);
    const [editingMember, setEditingMember] = useState(null);

    const [showInviteModal, setShowInviteModal] = useState(false);
    const [showOtpModal, setShowOtpModal] = useState(false);
    const [showEditModal, setShowEditModal] = useState(false);
    const [showDeleteModal, setShowDeleteModal] = useState(false);

    const [fullName, setFullName] = useState("");
    const [email, setEmail] = useState("");
    const [role, setRole] = useState("");
    const [otp, setOtp] = useState("");

    const [agencyId, setAgencyId] = useState(null);
    const [memberId, setMemberId] = useState(null);


    useEffect(() => {
        const fetchRoles = async () => {
            try {
                const response = await api.get("get_role");
                setRoles(response.data);
                console.log("Fetched roles:", response.data);
            } catch (error) {
                console.error("Error fetching roles:", error);
            }
        };
        fetchRoles();
    }, []);



    useEffect(() => {
        fetchMembers();
    }, []);

    const fetchMembers = async () => {
        try {
            const response = await api.get("show_member");
            if (response.data.members && response.data.members.length > 0) {
                setMembers(response.data.members); // Set members if available
            } else {
                setMembers([]); // Set empty members array if no data is found
            }
        } catch (error) {
            console.error("Error fetching members:", error);
            setMembers([]); // Set empty members array in case of an error
        }
    };

    const handleInviteSubmit = async () => {
        try {
            const response = await api.post("invite_member", {
                name: fullName,
                email: email,
                role_id: role,
            });
            setAgencyId(response.data.agency_id);
            setMemberId(response.data.member_id);
            setShowInviteModal(false);
            setOtp("");
            setShowOtpModal(true);
            resetFields();
            setErrors({});
        } catch (error) {
            if (error.response && error.response.data && error.response.data.errors) {
                setErrors(error.response.data.errors);
            } else {
                console.error("Error inviting member:", error);
            }
        }
    };

    const handleOtpSubmit = async () => {
        try {
            const response = await api.post("invite_member_otp", {
                agency_id: agencyId,
                member_id: memberId,
                member_otp: otp,
            });
            if (response.data.success) {
                fetchMembers();
                setShowOtpModal(false);
                setOtpError("");
            } else {

                setOtpError(response.data.message || "Invalid OTP or member details.");
            }
        } catch (error) {
            if (error.response && error.response.data && error.response.data.message) {
                setOtpError(error.response.data.message);
            } else {
                setOtpError("An error occurred while verifying OTP.");
                console.error("Error verifying OTP:", error);
            }
        }
    };


    const handleOpenEditModal = async (memberUsername) => {
        try {
            const response = await api.get(`edit_show_member_record/${memberUsername}`);
            setEditingMember(response.data.member);
            setFullName(response.data.member.name);
            setEmail(response.data.member.email);


            const selectedRole = roles.find(role => role.name === response.data.role);
            setRole(selectedRole ? selectedRole.id : "");

            setShowEditModal(true);
        } catch (error) {
            console.error("Error fetching member details for edit:", error);
        }
    };



    const handleEditSubmit = async () => {
        try {
            await api.post(`edit_member_record/${editingMember.username}`, {
                name: fullName,
                email: email,
                role_id: role,
            });
            fetchMembers();
            setShowEditModal(false);
            resetFields();
        } catch (error) {
            console.error("Error updating member:", error);
        }
    };

    const handleOpenDeleteModal = (memberUsername) => {
        setSelectedMember(memberUsername);
        setShowDeleteModal(true);
    };

    const handleDelete = async () => {
        try {
            await api.delete(`delete_member/${selectedMember}`);
            fetchMembers();
            setShowDeleteModal(false);
        } catch (error) {
            console.error("Error deleting member:", error);
        }
    };

    useEffect(() => {
        const fetchMembers = async () => {
            try {
                const response = await api.get(`show_member?search=${searchQuery}`);
                setMembers(response.data.members);
            } catch (error) {
                console.error("Error fetching members:", error);
            }
        };

        if (searchQuery.trim()) {
            fetchMembers();
        } else {
            fetchMembers();
        }
    }, [searchQuery]);



    const handleCloseModal = () => {
        setShowInviteModal(false);
        setShowOtpModal(false);
        setShowEditModal(false);
        setShowDeleteModal(false);
        setOtp("");
        resetFields();
    };


    const resetFields = () => {
        setFullName("");
        setEmail("");
        setRole("");
    };

    const toggleItem = (index) => {
        setOpenItemIndex(openItemIndex === index ? null : index);
    };

    return (
        <div className="members-page">
            {/* Header */}
            <div className="members-header d-flex justify-content-between align-items-center p-4">
                <div className="d-flex align-items-center">
                    <h2 className="heading-main">Manage Members</h2>
                </div>

                <div className="d-flex align-items-center">
                    <button
                        className="btn custom-button text-white"
                        onClick={() => setShowInviteModal(true)}
                    >
                        INVITE MEMBER
                    </button>
                </div>
            </div>

            {/* Search and Filter */}
            <div className="search-filter-container d-flex align-items-center">
                <div className="search-bars_pyout me-3">
                    <input
                        type="text"
                        className="search-inputs"
                        placeholder="Search By Members"
                        value={searchQuery}
                        onChange={(e) => setSearchQuery(e.target.value)}
                    />
                    <img
                        src={`${process.env.PUBLIC_URL}/icons/Search.svg`}
                        alt="Search"
                        className="search-icon"
                        style={{ cursor: "pointer" }}

                    />
                </div>
            </div>


            {/* Members Table */}
            <div className="members-table-wrapper mt-4">
                {members.length > 0 ? (
                    <table className="members-table">
                        <thead>
                            <tr>
                                <th>Member</th>
                                <th>Email-id</th>
                                <th>Role</th>
                                <th>Action</th>
                            </tr>
                        </thead>
                        <tbody>
                            {members.map((member) => (
                                <tr key={member.id}>
                                    <td>{member.member.name}</td>
                                    <td>{member.member.email}</td>
                                    <td>{member.role.display_name}</td>
                                    <td>
                                        <img
                                            src={`${process.env.PUBLIC_URL}/icons/EditAction.svg`}
                                            alt="EditAction"
                                            className="members-icon"
                                            style={{ marginRight: "10px" }}
                                            onClick={() => handleOpenEditModal(member.member.username)}
                                        />
                                        <img
                                            src={`${process.env.PUBLIC_URL}/icons/DeleteAction.svg`}
                                            alt="DeleteAction"
                                            className="members-icon"
                                            style={{ marginRight: "10px" }}
                                            onClick={() => handleOpenDeleteModal(member.member.username)}
                                        />
                                    </td>
                                </tr>
                            ))}
                        </tbody>
                    </table>
                ) : (
                    <div className="no-content-wrapper" style={{ textAlign: 'center' }}>
                        <img
                            src={`${process.env.PUBLIC_URL}/icons/NoContentAvail.svg`}
                            alt="No Content Available"
                            style={{ width: '50%' }}
                        />
                        <p>No Members Found </p>
                    </div>
                )}
            </div>

            {/* Mobile Toggle View */}
            <div className="members-mobile-view">
                {members.length > 0 ? (
                    members.map((member, index) => (
                        <div className="m-gigs-purchase" key={index}>
                            <div className="purchase-btn" onClick={() => toggleItem(index)}>
                                <div className="text">
                                    <h3>{member.member.name}</h3>
                                </div>
                                <div className="arrow">
                                    <img
                                        src={`${process.env.PUBLIC_URL}/icons/Arrow.svg`}
                                        alt="toggle"
                                        className={openItemIndex === index ? "arrow_up" : "arrow_down"}
                                    />
                                </div>
                            </div>

                            {openItemIndex === index && (
                                <div className="m-purchase-body">
                                    <div className="m-purchase-text">
                                        <p>Email-id</p>
                                        <h5>{member.member.email}</h5>
                                    </div>
                                    <div className="m-purchase-text">
                                        <p>Role</p>
                                        <h5>{member.role.display_name}</h5>
                                    </div>
                                    <div className="m-purchase-text">
                                        <p>Action</p>
                                        <h5>
                                            <img
                                                src={`${process.env.PUBLIC_URL}/icons/EditAction.svg`}
                                                alt="EditAction"
                                                className="invoice-icon"
                                                style={{ marginRight: "10px" }}
                                                onClick={() => handleOpenEditModal(member.member.username)}
                                            />
                                            <img
                                                src={`${process.env.PUBLIC_URL}/icons/DeleteAction.svg`}
                                                alt="DeleteAction"
                                                className="invoice-icon"
                                                style={{ marginRight: "10px" }}
                                                onClick={() => handleOpenDeleteModal(member.member.username)}
                                            />
                                        </h5>
                                    </div>
                                </div>
                            )}
                        </div>
                    ))
                ) : (
                    <div className="no-content-wrapper" style={{ textAlign: 'center' }}>
                        <img
                            src={`${process.env.PUBLIC_URL}/icons/NoContentAvail.svg`}
                            alt="No Content Available"
                            style={{ width: '50%' }}
                        />
                        <p>No Members Found </p>
                    </div>
                )}
            </div>


            {/* Invite Member Modal */}
            <Modal show={showInviteModal} onHide={handleCloseModal} centered dialogClassName="custom-modal">
                <Modal.Header>
                    <Modal.Title>Invite Member</Modal.Title>
                    <img
                        src={`${process.env.PUBLIC_URL}/icons/cancel_o.svg`}
                        alt="Close"
                        onClick={handleCloseModal}
                        style={{ cursor: "pointer" }}
                    />
                </Modal.Header>
                <Modal.Body>
                    <Form>
                        <Form.Group controlId="fullName">
                            <Form.Label>Full Name</Form.Label>
                            <Form.Control
                                type="text"
                                placeholder="Enter full name"
                                className="input-field"
                                value={fullName}
                                onChange={(e) => setFullName(e.target.value)}
                            />
                        </Form.Group>
                        <Form.Group controlId="emailId" className="mt-3">
                            <Form.Label>Email-id</Form.Label>
                            <Form.Control
                                type="email"
                                placeholder="Enter email-id"
                                className="input-field"
                                value={email}
                                onChange={(e) => {
                                    setEmail(e.target.value);
                                    if (errors.email) {
                                        setErrors({ ...errors, email: "" });
                                    }
                                }}
                                isInvalid={!!errors.email}
                            />
                            {errors.email && (
                                <Form.Control.Feedback type="invalid">
                                    {errors.email[0]}
                                </Form.Control.Feedback>
                            )}
                        </Form.Group>

                        <Form.Group controlId="role" className="mt-3">
                            <Form.Label>Role</Form.Label>
                            <Form.Control
                                as="select"
                                className="input-field"
                                value={role}
                                onChange={(e) => setRole(e.target.value)}
                            >
                                <option value="">Choose Role</option>
                                {roles.map((role) => (
                                    <option key={role.id} value={role.id}>
                                        {role.display_name}
                                    </option>
                                ))}
                            </Form.Control>
                        </Form.Group>
                    </Form>
                </Modal.Body>
                <Modal.Footer className="members_custom-modal-footer">
                    <Button className="members_submit-button" onClick={handleInviteSubmit}>
                        SUBMIT
                    </Button>
                </Modal.Footer>
            </Modal>

            {/* OTP Modal */}
            <Modal show={showOtpModal} onHide={handleCloseModal} centered dialogClassName="custom-modal">
                <Modal.Header>
                    <Modal.Title>Verify OTP</Modal.Title>
                    <img
                        src={`${process.env.PUBLIC_URL}/icons/cancel_o.svg`}
                        alt="Close"
                        onClick={handleCloseModal}
                        style={{ cursor: "pointer" }}
                    />
                </Modal.Header>
                <Modal.Body>
                    <Form>
                        <Form.Group controlId="otp">
                            <Form.Label>Please enter the OTP sent to your email</Form.Label>
                            <Form.Control
                                type="text"
                                placeholder="Enter OTP"
                                className="input-field"
                                value={otp}
                                onChange={(e) => {
                                    setOtp(e.target.value);
                                    if (otpError) {
                                        setOtpError("");
                                    }
                                }}
                                isInvalid={!!otpError}
                            />
                            {otpError && (
                                <Form.Control.Feedback type="invalid">
                                    {otpError}
                                </Form.Control.Feedback>
                            )}
                        </Form.Group>

                    </Form>
                </Modal.Body>
                <Modal.Footer
                    className="members_custom-modal-footer"
                    style={{
                        marginLeft: "0px !important",
                        width: "100% !important",
                        boxShadow: "none !important",
                        marginBottom: "0px !important",
                    }}
                >
                    <Button className="members_submit-button" onClick={handleOtpSubmit}>
                        SUBMIT
                    </Button>
                </Modal.Footer>
            </Modal>


            {/* Edit Member Modal */}
            <Modal show={showEditModal} onHide={handleCloseModal} centered dialogClassName="custom-modal">
                <Modal.Header>
                    <Modal.Title>Edit Member</Modal.Title>
                    <img
                        src={`${process.env.PUBLIC_URL}/icons/cancel_o.svg`}
                        alt="Close"
                        onClick={handleCloseModal}
                        style={{ cursor: "pointer" }}
                    />
                </Modal.Header>
                <Modal.Body>
                    <Form>
                        <Form.Group controlId="fullName">
                            <Form.Label>Full Name</Form.Label>
                            <Form.Control
                                type="text"
                                placeholder="Enter full name"
                                className="input-field"
                                value={fullName}
                                onChange={(e) => setFullName(e.target.value)}
                            />
                        </Form.Group>
                        <Form.Group controlId="emailId" className="mt-3">
                            <Form.Label>Email-id</Form.Label>
                            <Form.Control
                                type="email"
                                placeholder="Enter email-id"
                                className="input-field"
                                value={email}
                                onChange={(e) => setEmail(e.target.value)}
                            />
                        </Form.Group>
                        <Form.Group controlId="role" className="mt-3">
                            <Form.Label>Role</Form.Label>
                            <Form.Control
                                as="select"
                                className="input-field"
                                value={role}
                                onChange={(e) => setRole(e.target.value)}
                            >
                                <option value="">Choose Role</option>
                                {roles.map((role) => (
                                    <option key={role.id} value={role.id}>
                                        {role.display_name}
                                    </option>
                                ))}
                            </Form.Control>
                        </Form.Group>

                    </Form>
                </Modal.Body>
                <Modal.Footer className="members_custom-modal-footer">
                    <Button className="members_submit-button" onClick={handleEditSubmit}>
                        UPDATE
                    </Button>
                </Modal.Footer>
            </Modal>


            {/* Delete Confirmation Modal */}
            <Modal show={showDeleteModal} onHide={handleCloseModal} centered dialogClassName="delete-modal">
                <Modal.Header>
                    <Modal.Title>Delete Member</Modal.Title>
                    <img
                        src={`${process.env.PUBLIC_URL}/icons/cancel_o.svg`}
                        alt="Close"
                        onClick={handleCloseModal}
                        style={{ cursor: "pointer" }}
                    />
                </Modal.Header>
                <Modal.Body>
                    <p>Are you sure you want to delete this member?</p>
                </Modal.Body>
                <Modal.Footer
                    className="delete-modal-footer"
                    style={{
                        display: "flex",
                        justifyContent: "center",
                        gap: "15px",
                        width: "100%",
                        paddingBottom: "10px",
                    }}
                >
                    <Button
                        className="delete-cancel-button"
                        onClick={handleCloseModal}
                    >
                        CANCEL
                    </Button>
                    <Button className="delete-confirm-button" onClick={handleDelete}>
                        DELETE
                    </Button>
                </Modal.Footer>
            </Modal>
        </div>
    );
};

export default Members;
