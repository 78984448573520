import React from 'react';
import { Navbar, Container, Button } from 'react-bootstrap';
import { Link, useLocation ,useNavigate } from 'react-router-dom';
import './Header.css'; // Custom CSS

const Header = ({ creatorName }) => { 
  const location = useLocation();
  const navigate = useNavigate();

  const getPageTitle = () => {
    if (location.pathname.startsWith('/creatorprofile')) {
      return creatorName || 'Loading...'; 
    }
    if (location.pathname.startsWith('/reset-password')) {
      return 'Reset Your Password';
    }
    switch (location.pathname) {
      case '/':
        return 'Dashboard';
      case '/creators':
        return 'Creators';
      case '/payouts':
        return 'Payouts';
      case '/transactions':
        return 'Transactions';
      case '/invitemanagers':
        return 'Managers';
      case '/roles':
        return 'Roles';
      case '/revenuesplits':
        return 'Managers';
      case '/profile':
        return 'Profile';
      case '/settings':
        return 'Settings';
      case '/forgot-password':
        return 'Forgot Password';
        case '/members':
          return 'Members';
        
      default:
        return '';
    }
  };

  return (
    <Navbar className="custom-header">
      <Container fluid>
        {/* Logo (Only for mobile and tablet view) */}
        <div className="header-logo">
          <img src="/logo/logo.svg" alt="Logo" />
        </div>

        {/* Back Button */}
        <div className="back-button">
          <Link onClick={() => navigate(-1)}>
            <img  src={`${process.env.PUBLIC_URL}/icons/Hederbackspace.svg`} alt="Back" className="back-icons" />
            <span></span>
          </Link>
        </div>

        {/* Dynamic Page Title (only visible on larger screens) */}
        <div className="page-title">
          <span>{getPageTitle()}</span>
        </div>

        {/* Right Icons */}
        <div className="header-icons">
          {/* <Button variant="link" className="icon-button">
            <img  src={`${process.env.PUBLIC_URL}/icons/Message.svg`} alt="Messages" className="header-icon" />
          </Button> */}
          <Button variant="link" className="icon-button">
            <img  src={`${process.env.PUBLIC_URL}/icons/Notifaction.svg`} alt="Notifications" className="header-icon" />
          </Button>
        </div>
      </Container>
    </Navbar>
  );
};

export default Header;
