import { AuthActionTypes } from './AuthActionTypes';
import { persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage';

const initialState = {
    isAuthenticated: false,
    token: null,
  };
  
  const authReducer = (state = initialState, action) => {
    switch (action.type) {
      case AuthActionTypes.LOGIN_SUCCESS:
        return {
          ...state,
          isAuthenticated: true,
          token: action.payload.token,
        };
      case AuthActionTypes.LOGOUT:
        return {
          ...state,
          isAuthenticated: false,
          token: null,
        };
      default:
        return state;
    }
  };

  const persistConfig = {
    key: 'auth',
    storage,
  };
  
  export default persistReducer(persistConfig, authReducer);