import React, { useState, useEffect, useRef } from "react";
import { Link } from "react-router-dom";
import { Collapse, Button, Modal } from "react-bootstrap";
import "./Sidebar.css";
import { useDispatch } from 'react-redux';
import { logout } from '../../Redux/Auth/AuthActions';

const Sidebar = () => {
  const [isSidebarOpen, setIsSidebarOpen] = useState(false);
  const [isProfileMenuOpen, setIsProfileMenuOpen] = useState(false);
  const [isLaptop, setIsLaptop] = useState(window.innerWidth >= 769);
  const [showLogoutModal, setShowLogoutModal] = useState(false);
  const dispatch = useDispatch();

  const sidebarRef = useRef(null);
  const modalRef = useRef(null);

  useEffect(() => {
    const handleResize = () => {
      setIsLaptop(window.innerWidth >= 769);
    };

    const handleClickOutside = (event) => {
      if (sidebarRef.current && !sidebarRef.current.contains(event.target) && isSidebarOpen) {
        setIsSidebarOpen(false);
      }

      if (modalRef.current && !modalRef.current.contains(event.target) && showLogoutModal) {
        setShowLogoutModal(false);
      }
    };

    window.addEventListener("resize", handleResize);
    document.addEventListener("mousedown", handleClickOutside);

    return () => {
      window.removeEventListener("resize", handleResize);
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [isSidebarOpen, showLogoutModal]);

  const toggleSidebar = () => {
    setIsSidebarOpen(!isSidebarOpen);
  };

  const toggleProfileMenu = () => {
    setIsProfileMenuOpen(!isProfileMenuOpen);
  };

  const handleLinkClick = () => {
    if (!isLaptop) {
      setIsSidebarOpen(false);
    }
  };


  const handleLogoutClick = () => {
    setShowLogoutModal(true);
  };

  const handleCloseModal = () => {
    setShowLogoutModal(false);
  };

  const handleConfirmLogout = () => {
    dispatch(logout());
    setShowLogoutModal(false);
    window.location.href = 'https://staging.sneakpeach.com/login';

  };

  return (
    <div className="sidebar-container">
      {/* Sidebar */}
      <div
        ref={sidebarRef}
        className={`d-flex flex-column sidebar shadow-sm ${isSidebarOpen ? "open" : ""}`}
      >
        {/* Logo Section */}
        <div className="sidebar-logo bg-dark text-center">
          <img
            src="/logo/logo.svg"
            alt="Sneakpeach"
            style={{ width: "160px" }}
          />
        </div>

        {/* Navigation Menu */}
        <nav className="flex-grow-1" style={{ background: "#fff" }}>
          <ul className="list-unstyled">
            {/* Home Section */}
            <li className="menu-section">
              <h2 className="menu-heading">Home</h2>
              <Link
                to="/"
                className="menu-item d-flex align-items-center"
                onClick={handleLinkClick}
              >
                <img
                  src="/icons/Dashboard.svg"
                  alt="Dashboard"
                  className="menu-icon me-2"
                />
                Dashboard
              </Link>
            </li>

            {/* Manage Section */}
            <li className="menu-section">
              <h2 className="menu-heading">Manage</h2>
              <Link
                to="/creators"
                className="menu-item d-flex align-items-center"
                onClick={handleLinkClick}
              >
                <img
                  src="/icons/Creators.svg"
                  alt="Creators"
                  className="menu-icon me-2"
                />
                Creators
              </Link>
              <Link
                to="/transactions"
                className="menu-item d-flex align-items-center"
                onClick={handleLinkClick}
              >
                <img
                  src="/icons/Transactions.svg"
                  alt="Transactions"
                  className="menu-icon me-2"
                />
                Transactions
              </Link>
            </li>

            <li className="menu-section">
              <h2 className="menu-heading">TEAM</h2>
              <Link
                to="/members"
                className="menu-item d-flex align-items-center"
                onClick={handleLinkClick}
              >
                <img
                  src="/icons/Members.svg"
                  alt="Members"
                  className="menu-icon me-2"
                />
                Members 
              </Link>
            </li>


            {/* Financials Section */}
            <li className="menu-section">
              <h2 className="menu-heading">Financials</h2>
              <Link
                to="/revenuesplits"
                className="menu-item d-flex align-items-center"
                onClick={handleLinkClick}
              >
                <img
                  src="/icons/RevenueSpilts.svg"
                  alt="Revenue Splits"
                  className="menu-icon me-2"
                />
                Revenue Splits
              </Link>
              {/* <Link
                to="/payouts"
                className="menu-item d-flex align-items-center"
                onClick={handleLinkClick}
              >
                <img
                  src="/icons/payouts.svg"
                  alt="Payouts"
                  className="menu-icon me-2"
                />
                Payouts
              </Link> */}
            </li>

            {/* Profile Section (Mobile/Tablet Only) */}
            {!isLaptop && (
              <li className="menu-section">
                <h2 className="menu-heading">Profile</h2>
                <Link
                  to="/profile"
                  className="menu-item d-flex align-items-center"
                  onClick={handleLinkClick}
                >
                  <img
                    src="/icons/Agencyprofile.svg"
                    alt="Profile"
                    className="menu-icon me-2"
                  />
                  Agency Details
                </Link>
                <Link
                  to="/settings"
                  className="menu-item d-flex align-items-center"
                  onClick={handleLinkClick}
                >
                  <img
                    src="/icons/AccountSettings.svg"
                    alt="Settings"
                    className="menu-icon me-2"
                  />
                  Settings
                </Link>
                <Button
                  variant="link"
                  className="menu-item d-flex align-items-center"
                  onClick={handleLogoutClick}
                >
                  <img
                    src="/icons/Logout.svg"
                    alt="Logout"
                    className="menu-icon me-2"
                  />
                  Logout
                </Button>
              </li>
            )}
          </ul>
        </nav>

        {/* Profile Section for Laptop/Tablet View */}
        {isLaptop && (
          <div className="sidebar-footer border-top">
            <Button
              variant="link"
              className="d-flex align-items-center w-100 text-dark p-3"
              onClick={toggleProfileMenu}
              style={{ textDecoration: "none" }}
            >
              <img
                src="/logo/sidebar_logo.svg"
                alt="Profile"
                className="profile-icon me-2"
              />
              <span className="me-auto" style={{ textDecoration: "none" }}>
                sneakpeach
              </span>
              <img
                src="/icons/Arrow.svg"
                className={`icon-expand ${isProfileMenuOpen ? "rotate" : ""}`}
                alt="Expand"
              />
            </Button>

            <Collapse in={isProfileMenuOpen}>
              <div className="profile-menu">
                <Link
                  to="/profile"
                  className="profile-item"
                  onClick={handleLinkClick}
                >
                  <img src="/icons/Agencyprofile.svg" alt="Profile" />
                  Agency Profile
                </Link>
                <Link
                  to="/settings"
                  className="profile-item"
                  onClick={handleLinkClick}
                >
                  <img src="/icons/AccountSettings.svg" alt="Settings" />
                  Account Settings
                </Link>
                <Button
                  variant="link"
                  className="profile-item"
                  onClick={handleLogoutClick}
                >
                  <img src="/icons/Logout.svg" alt="Logout" />
                  Logout
                </Button>
              </div>
            </Collapse>
          </div>
        )}
      </div>

      {/* Modal for Logout Confirmation */}
      <Modal
        show={showLogoutModal}
        onHide={handleCloseModal}
        centered
        dialogClassName="custom-logout-modal"
      >
        <Modal.Header
          style={{
            backgroundColor: "#333",
            color: "#fff",
            borderBottom: "none",
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <Modal.Title>Log Out</Modal.Title>
          <Button
            variant="link"
            onClick={handleCloseModal}
            style={{
              color: "#fff",
              fontSize: "20px",
              border: "none",
              outline: "none",
              textDecoration: "none",
            }}
          >
            <img
              src="/icons/CancelOrange.svg"
              alt="Close"
              style={{ width: "25px" }}
            />
          </Button>
        </Modal.Header>
        <Modal.Body style={{ textAlign: "center", padding: "20px" }}>
          <p>Are you sure you want to logout?</p>
        </Modal.Body>
        <Modal.Footer style={{ justifyContent: "center", borderTop: "none" }}>
          <Button
            variant="outline-dark"
            onClick={handleCloseModal}
            style={{
              borderRadius: "16px",
              padding: "10px 55px",
            }}
          >
            Cancel
          </Button>
          <Button
            variant="warning"
            onClick={handleConfirmLogout}
            style={{
              backgroundColor: "#FF9F1C",
              border: "none",
              borderRadius: "16px",
              padding: "10px 55px",
              fontWeight: "bold",
              color: "#fff",
              marginLeft: "15px",
            }}
          >
            Logout
          </Button>
        </Modal.Footer>
      </Modal>

      {/* Bottom Navbar for mobile/tablet */}
      <div className="main-content">{/* Your content here */}</div>

      {/* Bottom Navbar for mobile/tablet */}
      <div className="bottom-navbar">
        <Link to="/dashboard">
          <img src="/icons/Dashboard.svg" alt="Home" />
          <span>Dashboard</span>
        </Link>
        <Link to="/creators">
          <img src="/icons/Creators.svg" alt="Explore" />
          <span>Creators</span>
        </Link>
        <Link to="/revenuesplits">
          <img src="/icons/RevenueSpilts.svg" alt="Gigs" />
          <span>Revenue Splits</span>
        </Link>
        <Link to="/profile" onClick={toggleSidebar}>
          <img src="/logo/sidebar_logo.svg" alt="Profile" />
          <span>Profile</span>
        </Link>
      </div>
    </div>
  );
};

export default Sidebar;
