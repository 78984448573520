
import { AuthActionTypes } from "./AuthActionTypes";
import axios from "axios";


  const getUnhashedToken = async (hashedToken) => {
    try {
        // const response = await axios.get(`http://127.0.0.1:8000/api/get-token/${hashedToken}`);
        const response = await axios.get(`${process.env.REACT_APP_BASE_URL}get-token/${hashedToken}`);
    
        const token = response.data.token;  // Access token from the response correctly
    
        console.log("token", token);  // This will show the token
        return token;  // Return the token directly
    
      } catch (error) {
        console.error('Error fetching unhashed token:', error);
        return null;  // In case of error, return null
      }
};

  
  // Login action
  export const login = (hashedToken) => {
    return async (dispatch) => {
      try {
        const unhashedToken = await getUnhashedToken(hashedToken);
        console.log("unhashedToken Login Token", unhashedToken)
        dispatch({
          type: AuthActionTypes.LOGIN_SUCCESS,
          payload: { token: unhashedToken },
        });
      } catch (error) {
        console.error('Error during login:', error);
      }
    };
  };
  
  // Logout action
  export const logout = () => {
    return (dispatch) => {
      dispatch({
        type: AuthActionTypes.LOGOUT,
      });
    };
  };